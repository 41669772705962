<template>
    <div class="login-page">
        <div class="login-content">
            <p class="title">阅卷及一键归档系统</p>
            <div class="form">
                <div class="form-prompt">账号登录</div>
                <!-- <div class="form-item">
                    <el-input v-model="formData.username" placeholder="用户名"></el-input>
                </div>
                <div class="form-item">
                    <el-input v-model="formData.password" type="password" placeholder="密码" @keydown.native="enter"></el-input>
                </div> -->
                <el-form ref="form" :model="formData" :rules="rules">
                    <el-form-item prop="username">
                        <el-input v-model="formData.username" placeholder="用户名"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="formData.password" type="password" placeholder="密码" @keydown.native="enter"></el-input>
                    </el-form-item>
                    <el-form-item prop="captcha" class="code-container" v-if="enabled">
                        <el-input placeholder="验证码" prefix-icon="el-icon-lock" v-model="formData.captcha" @keydown.native="enter"></el-input>
                        <img :src="codeURL" alt="" @click="refreshCode">
                    </el-form-item>
                </el-form>
                <button class="btn" @click="login" url="/user/login">登录</button>
                <div class="ques">没有账号？请联系网站管理员</div>
            </div>
        </div>
    </div>
</template>

<script>
import JSEncrypt from 'jsencrypt';
import { getCode } from '@/api/loginApi.js';
import { blobToDataURI } from '@/utils/file.js';

export default {
    data(){
        return {
            formData: {
                username: '',
                password: '',
                captcha: ''
            },
            rules: {
                username: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur",
                    },
                ],
                captcha: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur",
                    }
                ]
            },
            codeURL: ''
        }
    },
    computed: {
        enabled(){
            return this.$store.getters.configs['userConfig'].login.captcha.enabled;
        }
    },
    created(){
        this.refreshCode();
    },
    methods: {
        refreshCode(){  
            getCode().then(res => {
                blobToDataURI(res.data).then(dataurl => {
                    this.codeURL = dataurl;
                })
            })
        },
        login(){
            let params = {
                username: this.formData.username,
                password: this.formData.password,
                captcha: this.formData.captcha,
                token: '',
            }

            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.$store.dispatch('user/login',params).then(res => {
                        if(this.$route.query && this.$route.query.redirectUrl){
                            location.href = this.$route.query.redirectUrl;
                        }else{
                            this.$router.push({ path: this.$route.redirect || '/' });
                        }
                    }).catch(err => {
                        this.refreshCode();
                    }).finally(() => {
                        this.loading = false;
                    })
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });

            
        },
        enter(event){
            if(event.keyCode === 13){
                this.login();
            }
        }
    }
}
</script>

<style scoped lang='less'>
.login-page{
    width: 100%;
    height: 100vh;
    background: url('~@/assets/imgs/login.jpg') no-repeat 0 0/100% 100% ;
    display: flex;
    justify-content: center;
    align-items: center;
    .login-content{
        width: 82%;
        height: 80%;
        box-sizing: border-box;
        padding: 30px;
        .title{
            color: #1877D5;
            font-size: 30px;
        }
        .form{
            width: 45%;
            height: 90%;
            font-size: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            /deep/ .el-form{
                width: 70%;
            }
            .code-container{
                /deep/ .el-input{
                    margin-right: 10px;
                }
                /deep/ .el-form-item__content{
                    display: flex;
                    img{
                        cursor: pointer;
                    }
                }
            }
            .form-prompt{
                margin-bottom: 50px;
                font-size: 25px;
            }
            .btn{
                margin: 50px;
                background-color: #1877D5;
                color: #fff;
                width: 70%;
                height: 46px;
                font-size: 18px;
            }
            .ques{
                color: #aaa;
            }
        }
        
    }
}

</style>